module.exports = [{
      plugin: require('../node_modules/@outdoormap/gatsby-plugin-brand-sites/gatsby-browser.js'),
      options: {"plugins":[],"apiUrl":"https://api.naturkartan.se","apiKey":"4bdee188-7083-40a4-a23c-3a23e2fe553c","guideId":"202","siteUrl":"https://www.bruksleden.se","lang":"sv","typesCategories":{"hiking":["33","109","96","7"],"more":[]},"typesCategoriesIncluded":{"hiking":["33","109","96","7"],"more":[]},"imagePlaceholderColor":"#465555","templates":{"type":"/build/302b5a7b-7bf5-4557-9994-b4329b5c9242/src/templates/type.js","site":"/build/302b5a7b-7bf5-4557-9994-b4329b5c9242/src/templates/site.js","page":"/build/302b5a7b-7bf5-4557-9994-b4329b5c9242/src/templates/page.js","articles":"/build/302b5a7b-7bf5-4557-9994-b4329b5c9242/src/templates/articles.js","articlesPost":"/build/302b5a7b-7bf5-4557-9994-b4329b5c9242/src/templates/articles-post.js","events":"/build/302b5a7b-7bf5-4557-9994-b4329b5c9242/src/templates/events.js","eventsPost":"/build/302b5a7b-7bf5-4557-9994-b4329b5c9242/src/templates/events-post.js"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"lang":"sv","name":"Bruksleden","short_name":"Bruksleden","description":"Bruksleden","theme_color":"#29292b","background_color":"#fff","icon":"static/meta-images/icon.png","start_url":"/","display":"standalone","cache_busting_mode":"none","include_favicon":false,"legacy":false,"theme_color_in_head":true,"crossOrigin":"anonymous","cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
