import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import HexagonIcon from "../hexagon-icon"
import TypeIconSvg from "../type-icon-svg"
import { heading3Styles } from "../styled/heading"

const Container = styled.section`
  ${({ theme }) => theme.grid.container()}

  ul {
    display: flex;
    flex-wrap: wrap;
    margin: ${rem(21)} ${rem(-7)} 0;
  }

  li {
    min-width: 25%;
    flex: 1;
    display: flex;
    padding: ${rem(7)};

    @media ${({ theme }) => theme.mq.largeDown} {
      min-width: 50%;
    }

    @media ${({ theme }) => theme.mq.xsmallDown} {
      min-width: 100%;
    }
  }
`

const LinkStyled = styled(Link)`
  ${heading3Styles}
  ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

  width: 100%;
  height: 100%;
  padding: 0.8em 1em;
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: ${rem(18)};
  color: ${({ theme }) => theme.colors.lightBeige};
  background-color: ${({ theme, $color }) => theme.colors.firefly};
  border-radius: ${rem(5)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    min-width: 50%;
  }

  &:hover {
    opacity: 0.9;

    > .-icon {
      transform: scale(1.15);
    }
  }

  &:active {
    opacity: 0.6;
  }

  > .-icon {
    width: 2.5em;
    height: 2.5em;
    margin-right: 1em;
    transition: transform 0.3s ${({ theme }) => theme.easings.default};
  }
`

const TypeCtas = () => {
  const {
    config: { types },
  } = useStaticQuery(graphql`
    query {
      config: configJson {
        types {
          name
          slug
          title
        }
      }
    }
  `)

  return (
    <Container>
      <ul>
        {types.map(({ name, slug, title }, i) => (
          <li key={i}>
            <LinkStyled to={`/${slug}`}>
              <HexagonIcon
                iconSvg={<TypeIconSvg name={name} />}
                className="-icon"
                aria-hidden="true"
              />

              {title}
            </LinkStyled>
          </li>
        ))}
      </ul>
    </Container>
  )
}

export default TypeCtas
