import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem, hideVisually } from "polished"
import { markdown } from "@outdoormap/gatsby-plugin-brand-sites"

import HexagonIcon from "../hexagon-icon"
import TypeIconSvg from "../type-icon-svg"
import Styled from "../styled"
import { Heading1 } from "../styled/heading"
import { Label2 } from "../styled/label"

const Container = styled.section`
  ${({ theme }) => theme.grid.container()}
`

const Inner = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  border-radius: ${rem(8)};
  background-color: ${({ theme }) => theme.colors.white};
`

const Info = styled.div`
  width: 50%;

  @media ${({ theme }) => theme.mq.largeDown} {
    width: 100%;
  }

  ${Heading1} {
    max-width: ${rem(380)};
    margin-bottom: 0.5em;
  }

  ${Styled} {
    max-width: ${rem(420)};
  }
`

const Type = styled.header`
  padding: ${rem(40)} ${rem(50)};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ $color, theme }) => theme.colors[$color]};

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding: ${rem(20)};
  }

  > * {
    display: inline-block;
    vertical-align: middle;
  }

  > .-icon {
    font-size: 1.4em;
    margin-right: 0.8em;

    @media ${({ theme }) => theme.mq.mediumDown} {
      font-size: 0.875em;
    }
  }
`

const Title = styled.div`
  padding: ${rem(50)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding: ${rem(20)};
  }
`

const MapWrap = styled.div`
  width: 50%;
  min-height: 50vh;

  @media ${({ theme }) => theme.mq.largeDown} {
    width: 100%;
    height: ${rem(420)};
  }

  > h2 {
    ${hideVisually()}
  }
`

const TypeHero = ({
  children,
  type: { name, color, title, tagline, description },
  ...rest
}) => {
  return (
    <Container {...rest}>
      <Inner>
        <Info>
          <Type $color={color}>
            <HexagonIcon
              iconSvg={<TypeIconSvg name={name} />}
              className="-icon"
              aria-hidden="true"
              bgColor="white"
            />

            <Label2 as="h1" dangerouslySetInnerHTML={{ __html: title }} />
          </Type>

          {tagline && description && (
            <Title>
              {tagline && (
                <Heading1
                  as="h2"
                  dangerouslySetInnerHTML={{ __html: tagline }}
                />
              )}

              {description && (
                <Styled
                  dangerouslySetInnerHTML={{ __html: markdown(description) }}
                />
              )}
            </Title>
          )}
        </Info>

        <MapWrap>
          <h2>Map</h2>

          {children}
        </MapWrap>
      </Inner>
    </Container>
  )
}

TypeHero.propTypes = {
  children: PropTypes.node,
  type: PropTypes.object.isRequired,
}

export default TypeHero
