import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { hideVisually, rem } from "polished"
import { Meta } from "@outdoormap/gatsby-plugin-brand-sites"

import Layout from "../components/layout"
import Map from "../components/map"
import MediaBlock from "../components/media-block"
import TypeCtas from "../components/type-ctas"
import IndexSites from "../components/index-sites"
import PostsHighlights from "../components/posts-highlights"
import { ReactComponent as SvgBulb } from "../assets/images/icons/bulb.svg"

const MapWrap = styled.section`
  height: 80vh;
  min-height: ${rem(220)};
  margin-top: ${rem(60)};
  position: relative;

  @media ${({ theme }) => theme.mq.largeDown} {
    margin-top: ${rem(40)};
  }

  > h2 {
    ${hideVisually()}
  }

  > .gatsby-image-wrapper {
    width: ${rem(200)};
    height: ${rem(200)};
    position: absolute !important;
    z-index: 1;
    top: ${rem(-30)};
    right: ${rem(100)};
    pointer-events: none;
    filter: drop-shadow(0 ${rem(10)} ${rem(15)} rgba(0, 0, 0, 0.7));

    @media ${({ theme }) => theme.mq.largeDown} {
      width: ${rem(140)};
      height: ${rem(140)};
      right: ${rem(80)};
      filter: drop-shadow(0 ${rem(5)} ${rem(10)} rgba(0, 0, 0, 0.5));
    }

    @media ${({ theme }) => theme.mq.mediumDown} {
      display: none;
    }
  }
`

const Hr = styled.div`
  ${({ theme }) => theme.grid.container()}

  div {
    height: 0;
    margin-bottom: ${rem(40)};
    display: block;
    overflow: hidden;
    border-top: 1px solid ${({ theme }) => theme.colors.firefly};
  }
`

const IndexPage = ({
  data: {
    pageAbout,
    config,
    translations,
    latestArticles,
    hiking,
    more,
    imgCycling,
    imgMapPreview,
  },
}) => {
  const sites = []

  const findType = (name) => config.types.find((t) => t.name === name)

  if (hiking.nodes.length) {
    sites.push({ type: findType(`hiking`), items: hiking.nodes })
  }

  if (more.nodes.length) {
    sites.push({ type: findType(`more`), items: more.nodes })
  }

  const mapQuery = [
    `guide=${process.env.GATSBY_NATURKARTAN_GUIDE_ID}`,
    // ...[33, 116, 37, 34, 26, 31, 16, 55, 56].map((c) => `category=${c}`), // hiking,pilgrim-trail,canoeing,riding,fishing,biking,mountainbiking,hostel,camping
  ].join(`&`)

  return (
    <Layout>
      <MediaBlock
        headingLevel={1}
        descriptionHeadingLevel={2}
        data={{
          image: imgCycling,
          title: config.pages.index.hero.title,
          description: config.pages.index.hero.description,
          cta: {
            title: config.pages.index.hero.cta,
            url: pageAbout && pageAbout.path,
          },
        }}
      />

      <TypeCtas />

      <MapWrap>
        <h2 dangerouslySetInnerHTML={{ __html: translations.global.map }} />

        <GatsbyImage
          image={imgMapPreview.childImageSharp.gatsbyImageData}
          objectFit="contain"
          alt={translations.global.map}
        />

        <Map
          query={mapQuery}
          menu="fullscreen"
          scrollZoom="false"
          styleControl="fullscreen"
          zoomControl="true"
          measureControl="fullscreen"
          locationControl="fullscreen"
          printControl="fullscreen"
          legendControl="true"
          legend="true"
        />
      </MapWrap>

      {sites.map(({ type, items }, i) => (
        <React.Fragment key={i}>
          {i > 0 && (
            <Hr>
              <div />
            </Hr>
          )}

          <IndexSites type={type} sites={items} />
        </React.Fragment>
      ))}

      <PostsHighlights
        data={latestArticles.nodes}
        heading={translations.global.latestArticles}
        moreText={translations.global.seeAll}
        moreLink={`/${config.pages.articles.slug}`}
        icon={<SvgBulb />}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object,
}

export default IndexPage

export const Head = ({ data: { config } }) => {
  return (
    <Meta
      data={{
        title: config.pages.index.title,
        titleOverridePattern: true,
      }}
    />
  )
}

export const pageQuery = graphql`
  query {
    translations: translationsJson {
      global {
        map
        seeAll
        latestArticles
      }
    }

    config: configJson {
      pages {
        index {
          title
          hero {
            title
            description
            cta
          }
        }

        articles {
          slug
        }
      }

      types {
        name
        slug
        color
        title
        tagline
        description: description_short
      }
    }

    pageAbout: pagesJson(nk_id: { eq: "11476" }) {
      path
    }

    hiking: allSitesJson(
      filter: { the_type: { eq: "hiking" } }
      sort: { name: ASC }
      limit: 8
    ) {
      nodes {
        ...SitesCardFragment
      }
    }

    more: allSitesJson(
      filter: { the_type: { eq: "more" } }
      sort: { name: ASC }
      limit: 8
    ) {
      nodes {
        ...SitesCardFragment
      }
    }

    latestArticles: allArticlesJson(sort: { publish_at: DESC }, limit: 3) {
      nodes {
        ...ArticlesCardFragment
      }
    }

    imgCycling: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "content/bruksleden-hero.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1280)
      }
    }

    imgMapPreview: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "content/map-preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 768, placeholder: NONE)
      }
    }
  }
`
