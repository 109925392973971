import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"
import { ImagesCarousel } from "@outdoormap/gatsby-plugin-brand-sites"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import Map from "../map"
import SitesCarousel from "../sites-carousel"
import PostsCarousel from "../posts-carousel"
import Infobox from "../infobox"
import Styled from "../styled"
import { Heading1 } from "../styled/heading"
import { Label3 } from "../styled/label"
import { ParagraphLarge } from "../styled/paragraph"
import { anchorStyles } from "../styled/anchor"
import { ReactComponent as SvgArrowRight } from "../../assets/images/icons/arrow-right.svg"

const Article = styled.article`
  margin-bottom: ${rem(40)};
`

const Header = styled.header`
  ${({ theme }) => theme.grid.container(`xnarrow`)}

  margin-bottom: ${rem(50)};
  padding-top: ${rem(20)};
  text-align: center;

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-bottom: ${rem(20)};
  }
`

const Date = styled.time`
  margin-bottom: 0.875em;
  display: block;
  font-size: 1.125em;
  color: ${({ theme }) => theme.colors.firefly};
`

const MapWrap = styled.section`
  ${({ theme }) => theme.grid.container(`xnarrow`)}
  height: 80vh;
  min-height: ${rem(120)};
  max-height: ${rem(300)};
  z-index: 10;
  position: relative;
  margin-top: ${rem(40)};

  * {
    border-radius: ${rem(15)};
  }
`

const Title = styled(Heading1)``

const Tagline = styled(ParagraphLarge)`
  margin-top: 0.75em;
`

const Images = styled.div`
  ${({ theme }) => theme.grid.container(`narrow`)}

  aspect-ratio: 16 / 9;
  margin-bottom: ${rem(50)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-bottom: ${rem(20)};
  }
`

const Content = styled(Styled)`
  ${({ theme }) => theme.grid.container(`xnarrow`)}
`

const InfoboxContainer = styled.div`
  ${({ theme }) => theme.grid.container(`xnarrow`)}

  margin-top: ${rem(30)};

  &:empty {
    display: none;
  }

  @media ${({ theme }) => theme.mq.smallDown} {
    margin-top: ${rem(20)};
  }
`

const Sites = styled.footer`
  ${({ theme }) => theme.grid.container(`xnarrow`)}

  > div {
    margin-top: ${rem(40)};
    padding-top: ${rem(40)};
    border-top: 1px solid ${({ theme }) => theme.colors.white};
  }

  ${Label3} {
    margin-bottom: ${rem(20)};
  }
`

const Other = styled.section`
  background-color: ${({ theme }) => theme.colors.salmon};

  > div {
    ${({ theme }) => theme.grid.container()}

    padding-top: ${rem(50)};
    padding-bottom: ${rem(50)};

    @media ${({ theme }) => theme.mq.mediumDown} {
      margin-top: ${rem(20)};
      padding-top: ${rem(20)};
      padding-bottom: ${rem(20)};
    }
  }

  ${Label3} {
    margin-bottom: ${rem(20)};
    color: ${({ theme }) => theme.colors.firefly};
  }
`

const OtherCta = styled.footer`
  margin-top: ${rem(30)};

  a {
    ${anchorStyles}

    &:hover {
      svg {
        transform: translateX(0.3em);
      }
    }

    span {
      margin-right: 0.4em;
    }

    svg {
      width: 1.4em;
      height: 1.4em;
      vertical-align: middle;
      transition: transform 0.2s ${({ theme }) => theme.easings.default};
    }
  }
`

const Post = ({ data, other, otherHeading, otherCta, otherCtaUrl }) => {
  const { translations } = useStaticQuery(graphql`
    query {
      translations: translationsJson {
        global {
          mentionedInThisPost
        }
      }
    }
  `)

  const anySites = !!data.sites?.length
  const mapQuery = anySites
    ? data.sites.map((s) => `site=${s.id}`).join(`&`)
    : null

  if (!data) return null

  return (
    <React.Fragment>
      <Article>
        <Header>
          {data.date && (
            <Date
              datetime={data.date}
              dangerouslySetInnerHTML={{ __html: data.date }}
            />
          )}

          {data.title && (
            <Title dangerouslySetInnerHTML={{ __html: data.title }} />
          )}

          {data.tagline && (
            <Tagline dangerouslySetInnerHTML={{ __html: data.tagline }} />
          )}
        </Header>

        {!!data.images?.length && (
          <Images>
            <ImagesCarousel data={data.images} title={data.name} />
          </Images>
        )}

        {!!data.body && (
          <Content dangerouslySetInnerHTML={{ __html: data.body }} />
        )}

        <InfoboxContainer>
          <Infobox data={data} />
        </InfoboxContainer>

        {!!mapQuery && (
          <MapWrap>
            <Map
              query={mapQuery}
              menu="fullscreen"
              menuActive="fullscreen"
              filters="fullscreen"
              scrollZoom="false"
              styleControl="fullscreen"
              zoomControl="fullscreen"
              measureControl="fullscreen"
              locationControl="fullscreen"
              printControl="fullscreen"
            />
          </MapWrap>
        )}

        {anySites && (
          <Sites>
            <div>
              <Label3
                as="h2"
                dangerouslySetInnerHTML={{
                  __html: `${translations.global.mentionedInThisPost}…`,
                }}
              />

              <SitesCarousel visibleSlides={2} data={data.sites} />
            </div>
          </Sites>
        )}
      </Article>

      {other && !!other.nodes.length && (
        <Other>
          <div>
            {otherHeading && (
              <Label3
                as="h2"
                dangerouslySetInnerHTML={{ __html: otherHeading }}
              />
            )}

            <PostsCarousel visibleSlides={3} data={other.nodes} />

            {otherCta && otherCtaUrl && (
              <OtherCta>
                <Link to={otherCtaUrl}>
                  <span dangerouslySetInnerHTML={{ __html: otherCta }} />

                  <SvgArrowRight aria-hidden="true" />
                </Link>
              </OtherCta>
            )}
          </div>
        </Other>
      )}
    </React.Fragment>
  )
}

Post.propTypes = {
  data: PropTypes.object,
  other: PropTypes.object,
  otherHeading: PropTypes.string,
  otherCta: PropTypes.string,
  otherCtaUrl: PropTypes.string,
}

export default Post
