import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem, rgba } from "polished"
import { ImagesCarousel, Link } from "@outdoormap/gatsby-plugin-brand-sites"

import HexagonIcon from "../hexagon-icon"
import TypeIconSvg from "../type-icon-svg"
import { Label3, Label4 } from "../styled/label"
import { Heading1 } from "../styled/heading"
import { ParagraphSmall } from "../styled/paragraph"

const Container = styled.header`
  ${({ theme }) => theme.grid.container()}
`

const Inner = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  border-radius: ${rem(8)};
  background-color: ${({ theme }) => theme.colors.white};
`

const Info = styled.div`
  width: 50%;
  padding: ${rem(40)} ${rem(50)};
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.mq.largeDown} {
    width: 100%;
  }

  @media ${({ theme }) => theme.mq.smallDown} {
    padding: ${rem(20)};
  }

  ${Heading1} {
    max-width: ${rem(420)};
    margin-bottom: 0.5em;
  }
`

const TypeLink = styled(Link)`
  margin-bottom: 1em;
  display: flex;
  align-items: center;

  &:hover {
    > .-icon {
      transform: scale(1.15);
    }
  }

  &:active {
    opacity: 0.6;
  }

  > ${Label3} {
    margin-left: 0.75em;
    fill: ${({ theme }) => theme.colors.firefly};
    text-transform: none;
    ${({ theme }) => theme.fonts.set(`primary`, `bold`)}
  }

  > .-icon {
    transition: transform 0.3s ${({ theme }) => theme.easings.default};

    @media ${({ theme }) => theme.mq.smallDown} {
      width: 2em;
      height: 2em;
    }
  }
`

const Location = styled(ParagraphSmall)`
  color: ${({ theme }) => theme.colors.firefly};
`

const Length = styled(ParagraphSmall)`
  color: ${({ theme }) => theme.colors.firefly};
`

const Categories = styled.div`
  width: 100%;
  margin-top: auto;
  padding-top: 3em;
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 1px;
    position: absolute;
    top: 1.5em;
    left: 0;
    background-color: ${({ theme }) => rgba(theme.colors.white, 0.2)};
  }

  ${Label4} {
    margin-bottom: 1em;
  }

  ul {
    li {
      display: inline-block;
      margin-bottom: 1em;

      &:not(:last-child) {
        margin-right: 1.25em;
      }
    }

    img {
      width: 1.5em;
      margin-top: -0.15em;
      margin-right: 0.75em;
      display: inline-block;
      vertical-align: middle;
    }

    span {
      font-size: 0.875em; /* 14 */
    }
  }
`

const Images = styled.div`
  width: 50%;
  position: relative;
  min-height: ${rem(360)};

  @media ${({ theme }) => theme.mq.largeDown} {
    width: 100%;
    min-height: 0;
    aspect-ratio: 16 / 9;
  }
`

const SiteHero = ({
  data: { name, location, length, categories, images } = {},
  type,
}) => {
  const { translations } = useStaticQuery(graphql`
    query {
      translations: translationsJson {
        global {
          categories
        }
      }
    }
  `)

  return (
    <Container>
      <Inner>
        <Info>
          <TypeLink to={`/${type.slug}`} color={type.color}>
            <HexagonIcon
              iconSvg={<TypeIconSvg name={type.name} />}
              className="-icon"
              aria-hidden="true"
              bgColor={type.color}
            />

            <Label3 dangerouslySetInnerHTML={{ __html: type.title }} />
          </TypeLink>

          {name && <Heading1 dangerouslySetInnerHTML={{ __html: name }} />}

          {type.name !== `hiking` && location && (
            <Location dangerouslySetInnerHTML={{ __html: location }} />
          )}

          {type.name === `hiking` && length && <Length>{length} km</Length>}

          {type.name !== `hiking` && categories && categories.length > 0 && (
            <Categories>
              <Label4
                as="h2"
                dangerouslySetInnerHTML={{
                  __html: translations.global.categories,
                }}
              />

              <ul>
                {categories.map((category) => (
                  <li key={category.id}>
                    <img
                      src={`https://assets.naturkartan.se/assets/categories/${category.id}/main.svg`}
                      alt=""
                      role="presentation"
                    />

                    <span dangerouslySetInnerHTML={{ __html: category.name }} />
                  </li>
                ))}
              </ul>
            </Categories>
          )}
        </Info>

        <Images>
          <ImagesCarousel data={images} title={name} />
        </Images>
      </Inner>
    </Container>
  )
}

SiteHero.propTypes = {
  data: PropTypes.object,
  type: PropTypes.object.isRequired,
}

export default SiteHero
