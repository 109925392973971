export default {
  white: `#fff`,
  black: `#000`,
  firefly: `#08292f`,
  lightBeige: `#faf5eb`,
  salmon: `#ffb496`,
  fairwayMist: `#cce7b1`,
  onionSeedling: `#41885c`,
  red: `#d2281e`,
  purple: `#735967`,
}
